#barcodeScan {
    position: relative;
  }
  
  .scanner-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
  
  .scanner-guidelines {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    height: 50%;
    margin-top: -25%;
    margin-left: -25%;
    border: 2px solid limegreen;
    box-sizing: border-box;
    z-index: 2;
  }
  